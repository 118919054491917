import { Box, Button, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 999;
  ${"" /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */}
  @media screen and (max-width: 992px) {
    height: 4rem;
  }
`;

export const NavLinksBox = styled(Button)`
  display: flex;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  padding: 0.6rem 0.5rem;
  background: none;
  height: 100%;
  font-weight: 400;
  font-size: 16px;
  color: #319284;
  border: none;

  ${"" /* color: #ffffff; */}
  &:hover {
    color: #319284;
    border: none;
    background: none;
  }
  &:active {
    /* background: #10b981; */
  }

  @media screen and (max-width: 992px) {
    padding: 0.6rem 0.5rem;

    &:hover {
      color: #10b981;
      transition: all 0.3s ease;
    }

    &:active {
      background: #fff;
    }
  }
`;

export const NavLogo = styled(Link)`
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  /* padding: 0.5rem 1rem; */
  /* @media screen and (max-width: 992px) {
    clear:both;
    min-width: 220px;
    margin: auto;
  }; */
`;

export const NavFlex = styled(Flex)``;
