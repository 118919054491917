import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
const Login = React.lazy(() => import("../pages/auth/Login"));

const NoAuthRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}

export default NoAuthRoutes