import "./App.css";
import ScrollToTop from "./utilities/functions/ScrollToTop";
import { Suspense, } from "react";
import { ClipLoader } from "react-spinners";
import { Box, Center } from "@chakra-ui/react";
import { useAuth } from "./context/AuthContext";
import AdminRoutes from "./routes/AdminRoutes";
import NoAuthRoutes from "./routes/NoAuthRoutes";

function App() {
  const { token, checked } = useAuth();

  let routes;

  if (token) {
    routes = <AdminRoutes />;
  } else {
    routes = <NoAuthRoutes />;
  }

  return (
    <Box bgColor={"#F7FAFC"} minH='100vh'>
      <ScrollToTop />
      <Box minW='96vw'>
        <Suspense
          fallback={
            <Center alignSelf={"center"} minH="90vh">
              <ClipLoader size={45} color="#408ee1" />
            </Center>
          }
        >
          {checked ?
            routes
            :
            <Center minH='90vh'>
              <ClipLoader size={45} color='#408ee1' />
            </Center>
          }
        </Suspense>

      </Box>
    </Box>
  );
}

export default App;
